@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

/* ################# GENERAL ################ */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Bebas Neue", cursive;
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
}

a {
  text-decoration: none;
  color: #000000;
}

p {
  font-size: 2rem;
}

/* ################# HEADER ################ */

.whatsapp {
  background: #000000;
  padding: 1vh 0 1vh 0;
  font-size: 2rem;
  color: #ffffff;
}

.whatsapp div {
  text-align: center;
}

.whatsapp a {
  text-decoration: none;
  color: #ffffff;
}

.whatsapp a:hover {
  text-decoration: underline;
}

.whatsapp a span:hover {
  text-decoration: underline;
}

.whatsapp_logo {
  width: 22px;
}

.header {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #000000;
  padding: 3vh 0;
}

.header div {
  margin: 0 0 0 2vw;
}

.header h1 {
  width: 150%;
  font-size: 6rem;
  font-weight: 700;
}

.header p {
  font-size: 4rem;
  font-weight: 700;
}

#headline {
  width: 600px;
  display: flex;
  align-items: center;
}

.navbar {
  margin: 6.5vh 2vw 0 0;
  width: 40%;
  gap: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  list-style: none;
}

.navbar li {
  font-size: 2.5rem;
}

.navbar li a {
  font-weight: 700;
  text-decoration: none;
  color: #000000;
}

/* ################# MAIN / HOME ################ */

.main {
  margin: 2.5vh 2vw 0 2vw;
}

.main h2:nth-of-type(2) {
  margin: 1vh 0 0 0;
}

.top_headline {
  font-size: 2.5rem;
}

.home_headline {
  font-size: 2rem;
}

.home_content {
  font-size: 2rem;
  margin: 1.5vh 0;
}

.home_content:last-of-type {
  margin: 0 0 9vh 0;
}

/* ################# FOOTER ################ */

.footer {
  padding: 5vh 0 10vh 0;
  margin: 0 auto;
  background: #000000;
}

.navbar_footer {
  display: flex;
  justify-content: flex-end;
}

.navbar_footer ul {
  margin: 0 2vw 0 0;
  display: flex;
  gap: 2vw;
  justify-content: space-around;
  list-style-type: none;
}

.navbar_footer ul li {
  font-size: 2.5rem;
  font-weight: 700;
}

.navbar_footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

.ref_footer {
  margin: -4px 0 0 0;
}

.insta_logo {
  width: 30px;
}

/* ################# IMPRESSUM ################ */

.mapouter {
  position: relative;
  text-align: right;
  height: 300px;
  width: 400px;
}

.gmap_canvas {
  overflow: hidden;
  border-radius: 4px !important;
  background: none !important;
  height: 300px;
  width: 400px;
}

.absatz {
  margin: 0 0 2vh 0;
}

.imp_headline {
  margin: 1vh 0 0 0;
  font-size: 2rem;
}

.imp_p {
  margin: 1vh 0 0 0;
}

.imp_p_last {
  margin: 1vh 0 31vh 0;
}

/* ################# DATENSCHUTZ ################ */

.absatz ul {
  margin: 0 0 0 2vw;
}

.absatz ul li {
  font-size: 2rem;
}

/* ################# KONTAKT ################ */

.kontakt_absatz {
  margin: 2vh 0 0 0;
}

.contactform {
  margin: 2vh 0;
}

.inpt_name,
.inpt_email,
.inpt_phone,
.inpt_msg,
.submit_btn {
  padding: 5px 10px 5px 0;
}

.inpt_name input,
.inpt_email input,
.inpt_phone input,
.inpt_msg textarea {
  border-radius: 4px;
  padding: 5px 5px;
  background-color: #000000;
  color: #ffffff;
}

.inpt_msg textarea {
  width: 183px;
  height: 85px;
  resize: none;
}

.submit_btn button {
  border-radius: 4px;
  padding: 10px 25px;
  background-color: #000000;
  color: #ffffff;
  transition: 1s all;
}

.submit_btn button:hover {
  background-color: #ffffff;
  color: #000000; 
}

.inpt_name input::placeholder,
.inpt_email input::placeholder,
.inpt_phone input::placeholder,
.inpt_msg textarea::placeholder {
  color:#ffffff;
}

/* ################# Mobile Phone ################ */

@media screen and (max-width: 600px) {
  .App {
    overflow-x: hidden;
  }

  .header {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .header h1 {
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
  }

  #headline {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .navbar {
    display: flex;
    justify-content: center;
    gap: 30px
  }

  .absatz ul {
    margin: 0;
    padding-left: 3vw;
  }
  
  .absatz ul li {
    font-size: 1.8rem;
    margin-left: 2vw;
  }

  .navbar_footer {
    justify-content: flex-start;
    margin-left: 10px;
  }

  .navbar_footer ul {
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
  }

  .mapouter {
    text-align: unset;
  }

  .gmap_canvas iframe {
    width: 370px;
    height: 370px;
  }

  .inpt_name,
  .inpt_email,
  .inpt_phone,
  .inpt_msg,
  .submit_btn {
  padding: 5px 0;
}

  .inpt_name input,
  .inpt_email input,
  .inpt_phone input,
  .inpt_msg textarea,
  .submit_btn button {
  width: 100%;
  }
}

/* ################# Tablet ################ */

@media screen and (max-width: 1280px) {
  .App {
    overflow-x: hidden;
  }

  .header {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
  }

  .header h1 {
    width: 100%;
    font-size: 6rem;
    font-weight: 700;
  }

  #headline {
    width: 100%;
    display: flex;
    align-items: center;
  }

  #headline img {
    margin-left: 2vw;
  }

  .navbar {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .absatz ul {
    margin: 0;
    padding-left: 0;
  }
  
  .absatz ul li {
    font-size: 1.8rem;
    margin-left: 2vw;
  }

  .inpt_name,
  .inpt_email,
  .inpt_phone,
  .inpt_msg,
  .submit_btn {
  padding: 5px 0;
}

  .inpt_name input,
  .inpt_email input,
  .inpt_phone input,
  .inpt_msg textarea,
  .submit_btn button {
  width: 100%;
  }
}